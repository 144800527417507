<template>
  <div class="flex flex-col border border-bordercolor rounded-md h-36 p-3" @click="$emit('edit', data)">
    <div class="flex justify-between">
      <img :src="data?.payment_gateway_method_application?.payment_gateway_method?.payment_gateway?.image_url" alt="" class="w-24" />
      <span class="flex flex-row items-center space-x-2">
        <img :src="data?.payment_gateway_method_application?.payment_gateway_method?.payment_method?.image_url" alt="" />
        <p class="text-xs">
          {{ data?.payment_gateway_method_application?.payment_gateway_method?.payment_method?.name }}
        </p>
      </span>
    </div>
    <div class="flex-auto"></div>
    <div class="flex justify-between">
      <div v-if="showChannel" class="flex space-x-2 items-center">
        <p class="text-gray-400 text-sm">
          {{ $t('payment.settings.payment_provider.channel_used') }}
        </p>
        <div>
          <app-logo-senangpay
            class="mb-1"
            v-if="data?.payment_gateway_method_application?.payment_gateway_method?.channel == PAYMENT_CHANNEL.SENANG_PAY"
          />
          <app-logo-gobiz
            class="w-20"
            v-else-if="data?.payment_gateway_method_application?.payment_gateway_method?.channel == PAYMENT_CHANNEL.GO_BIZ"
          />
          <app-logo-stripe
            class="h-5"
            v-else-if="data?.payment_gateway_method_application?.payment_gateway_method?.channel == PAYMENT_CHANNEL.STRIPE"
          />
          <app-logo-iserve
            class="h-5"
            v-else-if=" data?.payment_gateway_method_application?.payment_gateway_method?.channel == PAYMENT_CHANNEL.ISERVE"
          />
          <p class="text-sm" v-else>{{ data?.payment_gateway_method_application?.payment_gateway_method?.channel }}</p>
        </div>
      </div>
      <div v-else></div>
      <div class="items-center">
        <app-form-switch :loading="loading" v-model="checkedValue" @click.stop>
          <p class="text-xs capitalize">{{ switchStatus }}</p>
        </app-form-switch>
      </div>
    </div>
  </div>
</template>

<script>
import PAYMENT_CHANNEL from '@/utils/const/payment_channel'

export default {
  data() {
    return {
      PAYMENT_CHANNEL: PAYMENT_CHANNEL,
      loading: false
    }
  },
  props: {
    data: {
      type: Object
    },
    showChannel: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async businessPaymentChanges(enabled) {
      this.resetState()
      this.loading = true
      let businessPaymentMethod = this.data
      businessPaymentMethod.enabled = enabled
      await this.$store.dispatch('paymentStore/createOrUpdateBusinessPaymentGatewayMethod', businessPaymentMethod)

      let business_id = businessPaymentMethod?.payment_gateway_method_application?.business_id

      this.$store.dispatch('paymentStore/getBusinessPaymentGatewayMethod', business_id)
      this.loading = false
    },
    resetState() {
      this.$store.commit('paymentStore/resetState', {
        errors: null
      })
    }
  },
  computed: {
    switchStatus() {
      return this.checkedValue == 1
        ? this.$t('payment.settings.payment_provider.enabled')
        : this.$t('payment.settings.payment_provider.disabled')
    },
    checkedValue: {
      get() {
        return this.data.enabled == 1
      },
      set(value) {
        this.businessPaymentChanges(value)
      }
    },
  }
}
</script>